import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button } from './ui/button';
import { SignInModal } from './SignInModal';
import { ThemeToggle } from './ThemeToggle';
import { Menu } from 'lucide-react';
import { cn } from '../lib/utils';
import { useUserStore } from '../stores/userStore';

const HeaderNavigation: React.FC = () => {
  const location = useLocation();
  const { currentUser, signOut } = useAuth();
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header 
      className={cn(
        'bg-white dark:bg-gray-950 shadow-sm fixed top-0 left-0 right-0 z-50 h-[50px]',
        isScrolled ? 'border-b border-gray-200 dark:border-gray-800' : ''
      )}
    >
      <div className="container mx-auto h-full px-8 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <div 
            className="logo bg-contain bg-no-repeat bg-center h-6 w-24"
            aria-label="Ctrl AI Logo" 
          />
        </Link>
        
        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-4 h-full">
          <nav className="flex items-center space-x-4 mr-4 h-full">
            {currentUser ? (
              <>
                <Link 
                  to="/dashboard" 
                  className="text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                >
                  Dashboard
                </Link>
                <Link 
                  to={`/u/${currentUser.uid}`} 
                  className="text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                >
                  My Profile
                </Link>
                <Link 
                  to="/user-settings" 
                  className="text-sm text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
                >
                  Settings
                </Link>
              </>
            ) : (
              <Link 
                to="/onboarding" 
                className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full"
              >
                Create Profile
              </Link>
            )}
          </nav>
          <div className="flex items-center space-x-4 h-full">
            {currentUser ? (
              <Button 
                onClick={signOut} 
                variant="outline"
                className="text-sm py-3 px-3"
              >
                Log out
              </Button>
            ) : (
              <Button 
                onClick={() => setIsSignInModalOpen(true)} 
                variant="outline"
                className="text-sm py-1 my-3 px-3"
              >
                Sign In
              </Button>
            )}
            <ThemeToggle />
          </div>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden p-2 flex items-center"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <Menu className="h-5 w-5" />
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden absolute top-[50px] left-0 right-0 bg-white dark:bg-gray-950 border-t border-gray-200 dark:border-gray-800">
          <nav className="flex flex-col space-y-4 px-8 py-4">
            {currentUser ? (
              <>
                <Link 
                  to={`/u/${currentUser.uid}`} 
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  My Profile
                </Link>
                <Link 
                  to="/dashboard" 
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Dashboard
                </Link>
                <Link 
                  to="/user-settings" 
                  className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Settings
                </Link>
              </>
            ) : (
              <Link 
                to="/onboarding" 
                className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Create Profile
              </Link>
            )}
            <div className="flex items-center space-x-4 pt-4">
              {currentUser ? (
                <Button onClick={() => { signOut(); setIsMobileMenuOpen(false); }} variant="outline">
                  Log out
                </Button>
              ) : (
                <Button onClick={() => { setIsSignInModalOpen(true); setIsMobileMenuOpen(false); }} variant="outline">
                  Sign In / Sign Up
                </Button>
              )}
              <ThemeToggle />
            </div>
          </nav>
        </div>
      )}
      <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />
    </header>
  );
};

export default HeaderNavigation;
