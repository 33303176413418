import React, { useState } from 'react';
import { DemoBadgeSection } from '../components/demo/DemoBadgeSection';
import { Button } from '../components/ui/button';
import { motion } from 'framer-motion';
import { Users as UsersIcon, Clock as ClockIcon, BarChart as ChartIcon, Rocket as RocketIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function HomePage() {
  const [isDemoComplete, setIsDemoComplete] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleDemoComplete = () => {
    setIsDemoComplete(true);
  };

  const handleCreateBadge = () => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      navigate('/onboarding');
    }
  };

  return (
    <>
      <div className="min-h-screen">
        {/* Full-width hero headline */}
        <div className="w-full text-left pt-[50px] px-6">
          <motion.h1 
            className="text-4xl lg:text-6xl font-bold leading-tight max-w-4xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            Empowering Professionals to
            <span className="text-blue-500 dark:text-blue-500 block">Lead the AI Revolution</span>
            Not Be Replaced By It
          </motion.h1>
        </div>

        {/* Split section */}
        <div className="flex flex-col lg:flex-row mt-6">
          {/* Left Side - Badge Display */}
          <div className="w-full lg:w-1/2 flex items-center justify-center p-4">
            <div className="relative w-full max-w-xl">
              <DemoBadgeSection onDemoComplete={handleDemoComplete} />
            </div>
          </div>

          {/* Right Side - Hero Content */}
          <div className="w-full lg:w-1/2 flex items-center px-6 lg:px-12">
            <div className="space-y-8">
              <div className="p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg inline-block">
                <p className="text-xl font-semibold text-blue-900 dark:text-blue-100">
                  "The winners of the AI age won't be those who use AI. They'll be those who use it best."
                </p>
              </div>

              <div className="mt-12 space-y-6">
                <p className="text-xl text-gray-600 dark:text-gray-300">
                  AI won't replace most professionals—but professionals who master AI integration will replace those who don't. The challenge? No one knows exactly how to enhance each professional task with AI.
                </p>
                
                <p className="text-xl font-semibold">
                  That's why we built Ctrl AI: Where professionals across 8,000+ fields are mapping exactly how to enhance 250,000+ tasks with AI, creating the world's first human-centric guide to professional AI integration.
                </p>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 mt-12">
                <Button 
                  size="lg" 
                  className="px-12 py-6 text-lg hover:bg-blue-500 rounded-full"
                  onClick={handleCreateBadge}
                >
                  Create Your Ctrl AI Profile
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Full-width sections with centered content */}
        <div className="flex flex-col items-center">
          {/* YouTube Video Section - full width */}
          <div className="w-full px-6">
            <div className="w-full aspect-video relative">
              <iframe
                src="https://www.youtube.com/embed/zRCukWl-8lM?autoplay=1&mute=1&controls=1&showinfo=0&rel=0&modestbranding=1&playsinline=1"
                className="w-full h-full rounded-2xl border mt-20 border-gray-200 dark:border-gray-800"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Ctrl AI Demo Video"
              ></iframe>
            </div>
          </div>

          {/* Centered sections with 70% width on large screens */}
          <div className="w-full lg:w-[70%] px-6">
            {/* Why Ctrl AI Section */}
            <section className="py-24 border border-gray-200 mt-24 px-6 md:px-12 dark:border-gray-800 rounded-2xl">
              <div className="space-y-12">
                <h2 className="text-3xl font-bold">Why We Need Collective Intelligence</h2>
                
                <div className="grid gap-8">
                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <ChartIcon className="h-8 w-8 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">See Exactly How Others Integrate AI</h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        No more guessing. Access real implementation insights from professionals in your field, task by task. See what works, what doesn't, and why.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <UsersIcon className="h-8 w-8 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Benchmark Against Your Peers</h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        Are you ahead or behind in AI adoption? Compare your AI integration with others in your profession. Identify opportunities before they become necessities.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <RocketIcon className="h-8 w-8 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Stay Ahead of the Transformation</h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        Your profession will change more in the next 5 years than in the past 50. Join the professionals actively mapping and mastering this change.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* How It Works */}
            <section className="py-24">
              <div className="space-y-12">
                <h2 className="text-3xl font-bold">Start Mastering AI Today</h2>

                <div className="space-y-8">
                  <div className="relative pl-8 border-l-2 border-blue-500">
                    <h3 className="text-xl font-semibold mb-2">Map Your Tasks</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      Identify which tasks in your workflow can be enhanced with AI. See how others in your field are already optimizing similar tasks.
                    </p>
                  </div>

                  <div className="relative pl-8 border-l-2 border-green-500">
                    <h3 className="text-xl font-semibold mb-2">Track Real Impact</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      Measure time saved and efficiency gained. Compare your results with profession benchmarks to identify optimization opportunities.
                    </p>
                  </div>

                  <div className="relative pl-8 border-l-2 border-violet-500">
                    <h3 className="text-xl font-semibold mb-2">Learn & Lead</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      Share insights, discover best practices, and help shape how your profession adapts to AI. Your experience guides others.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Social Proof */}
            <section className="py-24 px-6 md:px-12 border border-gray-200 dark:border-gray-800 rounded-2xl">
              <div className="space-y-12">
                <h2 className="text-3xl font-bold">The Professional AI Revolution</h2>
                <p className="text-gray-600 text-xl dark:text-gray-400">
                  Across every industry, professionals are discovering how to enhance their work with AI:
                </p>

                <div className="grid grid-cols-2 gap-8">
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">250k+</p>
                    <p className="text-gray-600 dark:text-gray-400">Professional tasks being enhanced</p>
                  </div>
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">83%</p>
                    <p className="text-gray-600 dark:text-gray-400">Report faster task completion</p>
                  </div>
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">8k+</p>
                    <p className="text-gray-600 dark:text-gray-400">Professions actively adapting</p>
                  </div>
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">4.2h</p>
                    <p className="text-gray-600 dark:text-gray-400">Average daily time saved</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Final CTA */}
            <section className="py-24">
              <div className="text-center space-y-8">
                <h2 className="text-3xl font-bold">
                  Don't Navigate This Change Alone
                </h2>
                <p className="text-xl text-gray-600 dark:text-gray-400 max-w-xl mx-auto">
                  Join professionals in your field who are already mapping the AI transformation of every task. Start with one task today.
                </p>
                <Button 
                  size="lg" 
                  className="px-12 py-6 text-lg hover:bg-blue-500 rounded-full"
                  onClick={handleCreateBadge}
                >
                  Join Your Professional Community
                </Button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}