// src/components/badge/RingSegments.tsx

import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { PlusIcon } from '@radix-ui/react-icons';
import { Task } from '../../types';
import { generateTaskColor } from '../../utils/colorUtils';
import classNames from 'classnames';
import { HoverCard, HoverCardTrigger, HoverCardContent } from '../ui/hover-card';
import { MdAccessTime, MdLink, MdOutlineSmartToy } from 'react-icons/md';
import { Portal } from '@radix-ui/react-portal';
import { Badge } from '../ui/badge';
import { useIsMobile } from '../../hooks/useIsMobile';

// Define the total minutes in a full-time work month
const FULL_TIME_MINUTES_PER_MONTH = 22 * 8 * 60; // 22 working days * 8 hours * 60 minutes

// Add a utility function at the top of the file
const formatUrl = (url: string) => {
  if (!url) return '#';
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://${url}`;
};

interface RingSegmentsProps {
  tasks: Task[];
  showAIOnly: boolean;
  isLoaded: boolean;
  handleTaskHover: (task: Task, index: number) => void;
  handleTaskClick: (task: Task, index: number) => void;
  handleTaskLeave: () => void;
  getAnimationStyle: (task: Task) => any;
  selectedTask: Task | null;
  hoveredTask: Task | null;
  isEditable: boolean;
  onEditTask?: (task: Task) => void;
  onAddTask?: () => void;
  onCloseTaskInsights?: () => void;
  onOpenShareModal?: () => void;
}

export const RingSegments: React.FC<RingSegmentsProps> = ({
  tasks,
  showAIOnly,
  isLoaded,
  handleTaskHover,
  handleTaskClick,
  handleTaskLeave,
  getAnimationStyle,
  selectedTask,
  hoveredTask,
  isEditable,
  onEditTask,
  onAddTask,
  onCloseTaskInsights,
  onOpenShareModal,
}) => {
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);
  const [initialHoverPosition, setInitialHoverPosition] = useState({ x: 0, y: 0 });
  const [isHoveringCard, setIsHoveringCard] = useState(false);
  
  // Add timeout ref to handle cleanup
  const timeoutRef = useRef<NodeJS.Timeout>();
  
  // Cleanup function for timeouts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const isMobile = useIsMobile();

  const handleSegmentMouseEnter = (e: React.MouseEvent, task: Task, index: number) => {
    if (isMobile) return; // Don't show hover card on mobile
    
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    if (!isHoveringCard) {
      setHoveredTaskId(task.taskId);
      handleTaskHover(task, index);
      setInitialHoverPosition({
        x: e.clientX,
        y: e.clientY
      });
    }
  };

  const handleSegmentMouseLeave = () => {
    if (!isHoveringCard) {
      timeoutRef.current = setTimeout(() => {
        setHoveredTaskId(null);
        handleTaskLeave();
      }, 100); // Small delay to allow moving to the card
    }
  };

  const handleCardMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsHoveringCard(true);
  };

  const handleCardMouseLeave = () => {
    setIsHoveringCard(false);
    timeoutRef.current = setTimeout(() => {
      setHoveredTaskId(null);
      handleTaskLeave();
    }, 100);
  };

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  };

  const TOTAL_ANGLE = 2 * Math.PI;
  const GAP_ANGLE = 0.01;
  const ADD_TASK_ANGLE = 0.2;  // Angle for the "Add Task" segment
  const TOTAL_GAP_ANGLE = GAP_ANGLE * tasks.length; // Remove +1 to reduce extra gap
  const AVAILABLE_ANGLE = TOTAL_ANGLE - TOTAL_GAP_ANGLE - ADD_TASK_ANGLE;

  const getPath = (start: number, end: number, innerR: number, outerR: number, counterClockwise: boolean) => `
    M ${innerR * Math.cos(start)} ${innerR * Math.sin(start)}
    A ${innerR} ${innerR} 0 ${Math.abs(end - start) > Math.PI ? 1 : 0} ${counterClockwise ? 0 : 1} 
      ${innerR * Math.cos(end)} ${innerR * Math.sin(end)}
    L ${outerR * Math.cos(end)} ${outerR * Math.sin(end)}
    A ${outerR} ${outerR} 0 ${Math.abs(end - start) > Math.PI ? 1 : 0} ${counterClockwise ? 1 : 0} 
      ${outerR * Math.cos(start)} ${outerR * Math.sin(start)}
    Z
  `;

  const handleSegmentClick = (task: Task, index: number) => {
    if (onCloseTaskInsights) {
      onCloseTaskInsights();
    }
    // Small delay before triggering the new click
    setTimeout(() => {
      handleTaskClick(task, index);
    }, 100);
  };

  const renderSegments = () => {
    let startAngle = -Math.PI / 2;
    let cumulativeAngle = 0;

    // Calculate total AI time across all tasks
    const totalAIMinutes = tasks.reduce((sum, task) => 
      sum + (task.aiAssistedTime * task.frequency), 0
    );

    // Determine the scaling factor if total time exceeds the full month
    const scalingFactor = Math.max(
      totalAIMinutes / FULL_TIME_MINUTES_PER_MONTH,
      1
    );

    const segments = tasks.map((task, index) => {
      const taskColor = generateTaskColor(task.taskId, task.taskName);
      const taskAIMinutes = task.aiAssistedTime * task.frequency;
      
      // Adjust segment angle based on scaling factor
      const segmentAngle = (AVAILABLE_ANGLE * taskAIMinutes) / 
        (FULL_TIME_MINUTES_PER_MONTH * scalingFactor);

      const segmentStartAngle = startAngle - cumulativeAngle;
      const segmentEndAngle = segmentStartAngle - segmentAngle;
      const innerRadius = 220;
      const outerRadius = 244;

      const segment = (
        <g key={task.taskId}>
          <motion.g
            onMouseEnter={(e) => handleSegmentMouseEnter(e, task, index)}
            onMouseLeave={handleSegmentMouseLeave}
            onClick={() => handleSegmentClick(task, index)}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ 
              opacity: 1, 
              scale: 1,
              filter: hoveredTask === task ? 'brightness(1.2)' : 'brightness(1)'
            }}
            whileHover={{ 
              scale: 1.05,
              filter: 'brightness(1.2)',
              transition: { duration: 0.2 }
            }}
            transition={{
              duration: 1.5,
              delay: index * 0.1
            }}
          >
            <motion.path
              d={getPath(segmentStartAngle, segmentEndAngle, innerRadius, outerRadius, true)}
              fill={taskColor}
              filter={`url(#glow-${index})`}
              initial={{ pathLength: 0 }}
              animate={{ 
                pathLength: 1,
                opacity: selectedTask === task ? [1, 0.8, 1] : 1
              }}
              transition={{
                pathLength: { duration: 1, delay: index * 0.1 },
                opacity: selectedTask === task ? 
                  { duration: 3, repeat: Infinity } : 
                  { duration: 0.3 }
              }}
              className={classNames({
                ['hovered']: hoveredTask === task,
              })}
            />
          </motion.g>

          {!isMobile && hoveredTaskId === task.taskId && (
            <Portal>
              <div
                style={{
                  position: 'fixed',
                  left: `${initialHoverPosition.x}px`,
                  top: `${initialHoverPosition.y}px`,
                  transform: 'translate(10px, 10px)',
                  zIndex: 1000,
                  pointerEvents: 'auto',
                }}
                onMouseEnter={handleCardMouseEnter}
                onMouseLeave={handleCardMouseLeave}
              >
                <div 
                  className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-3 max-w-[250px]"
                  style={{
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                  }}
                >
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2 break-words">
                    {task.taskName}
                  </h3>
                  <div className="flex gap-2 flex-wrap">
                    <Badge variant="secondary" className="text-xs">
                      <MdOutlineSmartToy className="h-3 w-3 mr-1" />
                      {task.aiToolsUsed?.[0]?.aiToolName || 'N/A'}
                    </Badge>
                    <Badge variant="outline" className="text-xs">
                      {task.aiAssistedPercentage}% saved
                    </Badge>
                  </div>
                </div>
              </div>
            </Portal>
          )}
        </g>
      );

      cumulativeAngle += segmentAngle + GAP_ANGLE;
      return segment;
    });

    if (isEditable && onAddTask && tasks.length > 0) {
      const addTaskStartAngle = startAngle - cumulativeAngle;
      const addTaskEndAngle = addTaskStartAngle - ADD_TASK_ANGLE;
      segments.push(
        <motion.g 
          key="add-task" 
          className="add-task-ring"
          onClick={() => {
            if (onCloseTaskInsights) {
              onCloseTaskInsights();
            }
            setTimeout(() => {
              onAddTask();
            }, 100);
          }}
          style={{ cursor: 'pointer' }}
          whileHover={{ scale: 1.1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 0.3 }}
        >
          <path
            d={getPath(addTaskStartAngle, addTaskEndAngle, 220, 244, true)}
            fill="#CCCCCC"
          />
          <foreignObject
            x={232 * Math.cos((addTaskStartAngle + addTaskEndAngle) / 2) - 12}
            y={232 * Math.sin((addTaskStartAngle + addTaskEndAngle) / 2) - 12}
            width="24"
            height="24"
          >
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}>
              <PlusIcon className="text-white" style={{ width: '20px', height: '20px' }} />
            </div>
          </foreignObject>
        </motion.g>
      );
    }

    return segments;
  };

  return (
    <g>
      <motion.circle
        cx="0"
        cy="0"
        r="232"
        fill="none"
        stroke="none"
        strokeWidth="24"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      />
      {renderSegments()}
    </g>
  );
};
