import React, { useState, useRef } from 'react';
import { useUserStore } from '../../stores/userStore';
import { ProfessionSelect } from '../ProfessionSelect';
import { ProfessionChangeModal } from '../ProfessionChangeModal';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/card';
import { Separator } from '../ui/separator';
import { useToast } from '../ui/use-toast';
import { Modal } from '../ui/modal';
import { ProfessionBasic, ProfessionHistoryEntry } from '../../types';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Label } from '../ui/label';
import { useAuth } from '../../contexts/AuthContext';
import { AuthButton } from '../AuthButton';
import { SignInModal } from '../SignInModal';
import { ProfilePictureUpload } from '../ProfilePictureUpload';
import { Textarea } from '../ui/textarea';
import Select from 'react-select/async';
import { Search } from 'lucide-react';
import { searchLocations } from '../../utils/locationSearch';
import { processImage } from '../../utils/imageUtils'; // Add this import



export const UserSettings: React.FC = () => {
  const { currentUser, updateUserData, updateProfilePicture } = useUserStore();
  const { currentUser: authUser } = useAuth();
  const { toast } = useToast();
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [isProfessionModalOpen, setIsProfessionModalOpen] = useState(false);
  const [newName, setNewName] = useState(currentUser?.name || '');
  const [professionToChange, setProfessionToChange] = useState<ProfessionBasic | null>(null);
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [bio, setBio] = useState(currentUser?.bio || '');
  const [location, setLocation] = useState(currentUser?.location || '');
  const [website, setWebsite] = useState(currentUser?.website || '');
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleSignUp = () => {
    setIsSignInModalOpen(true);
  };

  if (!authUser) {
    return (
      <div className="container max-w-2xl mx-auto py-8 px-4 text-center">
        <h1 className="text-2xl font-bold mb-4">Settings Unavailable</h1>
        <p className="text-gray-600 dark:text-gray-400 mb-6">
          Please sign in or create an account to customize your badge and access settings.
        </p>
        <AuthButton 
          onSignUp={() => setIsSignInModalOpen(true)}
          initialMode="signup"
        />
        
        <SignInModal 
          isOpen={isSignInModalOpen}
          onClose={() => setIsSignInModalOpen(false)}
          initialMode="signup"
        />
      </div>
    );
  }

  const handleNameChange = async () => {
    if (!newName.trim()) {
      toast({
        title: "Error",
        description: "Name cannot be empty",
        variant: "destructive"
      });
      return;
    }

    try {
      await updateUserData({ name: newName.trim() });
      setIsNameModalOpen(false);
      toast({
        title: "Success",
        description: "Name updated successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update name",
        variant: "destructive"
      });
    }
  };

  const handleProfessionSelect = async (profession: ProfessionBasic) => {
    setProfessionToChange(profession);
    setIsProfessionModalOpen(true);
  };

  const handleProfessionChange = async () => {
    if (!professionToChange || !currentUser?.id) return;

    try {
      // Simplified update data - just reset tasks and points
      const updateData = {
        professionId: professionToChange.id,
        professionName: professionToChange.name,
        lastProfessionChange: new Date().toISOString(),
        // Reset tasks, points, and AI level for non-premium users
        ...((!currentUser.isPremium) && {
          tasks: [],
          points: 0,
          aiLevel: 0
        })
      };

      await updateUserData(updateData);
      
      setIsProfessionModalOpen(false);
      setProfessionToChange(null);
      
      toast({
        title: "Success",
        description: currentUser.isPremium 
          ? "Profession changed successfully" 
          : "Profession changed successfully. Your tasks, points, and AI level have been reset.",
      });
    } catch (error) {
      console.error('Error changing profession:', error);
      toast({
        title: "Error",
        description: "Failed to change profession",
        variant: "destructive"
      });
    }
  };

  const handleProfileUpdate = async () => {
    if (!currentUser) return;

    try {
      setIsSaving(true);
      
      // Format website URL before saving
      let formattedWebsite = website;
      if (website && !website.startsWith('http://') && !website.startsWith('https://')) {
        formattedWebsite = `https://${website}`;
      }

      await updateUserData({
        bio: bio.slice(0, 160),
        location: location.slice(0, 30),
        website: formattedWebsite
      });

      toast({
        title: "Success",
        description: "Profile updated successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update profile",
        variant: "destructive"
      });
    } finally {
      setIsSaving(false);
    }
  };

  const renderProfileSection = () => (
    <div className="space-y-6">
      {/* Profile picture section */}
      <div className="flex flex-col items-center space-y-4">
        <ProfilePictureUpload size="lg" />
      </div>

      {/* Name section */}
      <div className="flex items-center justify-between pt-4 border-t">
        <div>
          <Label htmlFor="name" className="text-sm text-muted-foreground">Name</Label>
          <p className="text-lg font-medium">{currentUser?.name || 'Your Name'}</p>
        </div>
        <Button 
          variant="outline"
          onClick={() => setIsNameModalOpen(true)}
          className="ml-4"
        >
          Edit
        </Button>
      </div>
    </div>
  );

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !currentUser) return;
  
    try {
      setIsUploading(true);
  
      // Validate file type
      if (!file.type.startsWith('image/')) {
        toast({
          title: "Error",
          description: "Please upload an image file (JPG, PNG, etc.)",
          variant: "destructive"
        });
        return;
      }
  
      // Validate file size (5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        toast({
          title: "Error",
          description: "Image size should be less than 5MB",
          variant: "destructive"
        });
        return;
      }
  
      console.log('Processing image:', { 
        name: file.name, 
        type: file.type, 
        size: file.size 
      }); // Debug log
  
      // Process the image
      const processedImage = await processImage(file, 800); // Increased max dimension
  
      // Create a new File object from the processed blob
      const processedFile = new File([processedImage], file.name, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
  
      console.log('Processed image size:', processedFile.size); // Debug log
  
      // Upload the processed image
      await updateProfilePicture(currentUser.id, processedFile);
  
      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
  
      toast({
        title: "Success",
        description: "Profile picture updated successfully",
      });
    } catch (error) {
      console.error('Error processing image:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update profile picture",
        variant: "destructive"
      });
    } finally {
      setIsUploading(false);
    }
  };

  const loadLocationOptions = (inputValue: string) => searchLocations(inputValue);

  return (
    <div className="container max-w-2xl mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-6">Account Settings</h1>
      
      <Card className="border-gray-800 mb-6">
        <CardHeader>
          <CardTitle>Profile Information</CardTitle>
          <CardDescription>Manage your personal information</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          {renderProfileSection()}

          <Separator />

          {/* Bio Section */}
          <div className="space-y-2">
            <Label htmlFor="bio">Bio</Label>
            <Textarea
              id="bio"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Tell us about yourself"
              className="resize-none"
              maxLength={160}
            />
            <p className="text-sm text-gray-500">
              {bio.length}/160 characters
            </p>
          </div>

          {/* Location Section with Autocomplete */}
          <div className="space-y-2">
            <Label htmlFor="location">Location</Label>
            <Select
              className="location-select"
              classNamePrefix="location-select"
              loadOptions={loadLocationOptions}
              defaultValue={location ? { value: location, label: location } : null}
              onChange={(newValue: any) => setLocation(newValue?.value || '')}
              placeholder="Search for a city..."
              isClearable
              components={{
                DropdownIndicator: () => <Search className="mr-2 h-4 w-4 text-white" />
              }}
            />
          </div>

          {/* Website Section */}
          <div className="space-y-2">
            <Label htmlFor="website">Website</Label>
            <Input
              id="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              placeholder="https://your-website.com"
              maxLength={100}
            />
          </div>

          <Button 
            onClick={handleProfileUpdate} 
            className="w-full"
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Profile Changes'}
          </Button>
        </CardContent>
      </Card>

      {/* Profession Section */}
      <Card className="border-gray-800 mb-6">
        <CardHeader>
          <CardTitle>Professional Information</CardTitle>
          <CardDescription>Manage your profession and related settings</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <h3 className="font-medium">Current Profession</h3>
            <ProfessionSelect
              selectedProfession={currentUser ? {
                id: currentUser.professionId,
                name: currentUser.professionName
              } : null}
              onSelect={handleProfessionSelect}
              isInitialSelection={false}
            />
          </div>

        
        </CardContent>
      </Card>

      {/* Premium Section */}
      <Card className="border-gray-800 mb-6">
        <CardHeader>
          <CardTitle>Premium Status</CardTitle>
          <CardDescription>Manage your subscription</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-medium">
                {currentUser?.isPremium ? 'Premium Account' : 'Free Account'}
              </h3>

            </div>
            {!currentUser?.isPremium && (
              <Button variant="outline">
                Premium Membership coming soon
              </Button>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Name Change Modal */}
      <Modal
        isOpen={isNameModalOpen}
        onClose={() => setIsNameModalOpen(false)}
        title="Change Name"
        className={isMobile ? "modal-mobile" : ""}
      >
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">New Name</Label>
            <Input
              id="name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder="Enter new name"
              className="w-full"
            />
          </div>
          <div className="modal-footer">
            <Button 
              variant="outline" 
              onClick={() => setIsNameModalOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleNameChange}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal>

      {/* Profession Change Modal */}
      <ProfessionChangeModal
        isOpen={isProfessionModalOpen}
        onClose={() => {
          setIsProfessionModalOpen(false);
          setProfessionToChange(null);
        }}
        onConfirm={handleProfessionChange}
        currentProfession={currentUser?.professionName || ''}
        newProfession={professionToChange?.name || ''}
        isPremium={currentUser?.isPremium}
      />
    </div>
  );
};
