import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { useAuth } from '../contexts/AuthContext';
import { useMediaQuery } from '../hooks/use-media-query';
import { FcGoogle } from "react-icons/fc";
import { toast } from './ui/use-toast';
import { useUserStore } from '../stores/userStore';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialMode?: 'signin' | 'signup';
}

export const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose, initialMode = 'signin' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isSignUp, setIsSignUp] = useState(initialMode === 'signup');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, signUp, signInWithGoogle, sendEmailVerification } = useAuth();
  const [verificationSent, setVerificationSent] = useState(false);

  useEffect(() => {
    setIsSignUp(initialMode === 'signup');
  }, [initialMode, isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    
    try {
      if (isSignUp) {
        if (!name.trim()) {
          setError('Please enter your name');
          setIsLoading(false);
          return;
        }
        const userCredential = await signUp(email, password, name.trim());
        
        if (userCredential.user) {
          await useUserStore.getState().handleNewUserSignup(userCredential.user.uid);
          sessionStorage.setItem('verifyingUserId', userCredential.user.uid);
        }
        
        setVerificationSent(true);
        window.location.replace('/verify-email');
      } else {
        const userCredential = await signIn(email, password);
        if (!userCredential.user.emailVerified) {
          setError('Please verify your email before signing in');
          await sendEmailVerification();
          setVerificationSent(true);
          return;
        }
        onClose();
      }
    } catch (error: any) {
      console.error('SignIn/SignUp error:', error);
      setError(error.message || 'An error occurred during sign in/up');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError(null);
      setIsLoading(true);
      
      // First, clear any existing temp user data
      useUserStore.getState().resetUserState();
      
      const result = await signInWithGoogle();
      
      if (result.user) {
        // Check if user exists
        const docRef = doc(db, 'users', result.user.uid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          console.log('SignInModal - Existing user, fetching data');
          await useUserStore.getState().fetchUserData(result.user.uid);
        } else if (isSignUp) {
          console.log('SignInModal - New user signup');
          // Create new empty user without any temp data
          const now = new Date().toISOString();
          const newUserDoc = {
            id: result.user.uid,
            uid: result.user.uid,
            name: result.user.displayName || 'User',
            professionId: '',
            professionName: '',
            points: 0,
            aiLevel: 0,
            tasks: [],
            createdAt: now,
            updatedAt: now,
            bio: '',
            location: '',
            website: '',
            isPremium: false,
            profilePicture: {
              mainUrl: '',
              thumbnailUrl: ''
            },
            lastProfessionChange: now,
            ogData: {
              imageUrl: null,
              generated: false,
              lastGenerated: null
            }
          };
          
          await setDoc(docRef, newUserDoc);
          useUserStore.getState().currentUser = newUserDoc;
        }
      }
      
      onClose();
    } catch (error: any) {
      setError(error.message || 'An error occurred during Google sign in');
      setIsLoading(false);
    }
  };

  const renderVerificationMessage = () => {
    if (!verificationSent) return null;
    
    return (
      <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-md mb-4">
        <p className="text-sm text-yellow-800 dark:text-yellow-200">
          A verification email has been sent to {email}. Please check your inbox and spam folder to verify your email before signing in.
        </p>
        <Button 
          variant="link" 
          className="text-sm p-0 h-auto text-yellow-800 dark:text-yellow-200 underline"
          onClick={async () => {
            try {
              await sendEmailVerification();
              toast({
                title: "Verification email sent",
                description: "Please check your inbox and spam folder",
              });
            } catch (error) {
              toast({
                title: "Error",
                description: "Failed to send verification email. Please try again later.",
                variant: "destructive",
              });
            }
          }}
        >
          Resend verification email
        </Button>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{isSignUp ? 'Sign Up' : 'Sign In'}</DialogTitle>
          <DialogDescription>
            {isSignUp 
              ? 'Create your account to save your progress and personalize your badge.'
              : 'Sign in to your account to continue your AI journey.'}
          </DialogDescription>
        </DialogHeader>
        {renderVerificationMessage()}
        {error && (
          <div className="text-red-500 text-sm mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {isSignUp && (
            <Input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={isSignUp}
            />
          )}
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </Button>
        </form>
        <Button onClick={handleGoogleSignIn} variant="outline" className="w-full mt-2">
          <FcGoogle className="mr-2 h-5 w-5" />
          {isSignUp ? 'Sign up with Google' : 'Sign in with Google'}
        </Button>
        <Button onClick={() => setIsSignUp(!isSignUp)} variant="link" className="w-full mt-2">
          {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
