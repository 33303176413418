// src/pages/ProfilePage.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Badge from '../components/Badge';
import { useUserStore } from '../stores/userStore';
import { toast } from '../components/ui/use-toast';
import { ShareBadgeModal } from '../components/ShareBadgeModal';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';

const ProfilePage: React.FC = () => {
  const [layout, setLayout] = useState<'centered' | 'split'>('centered');
  const [showAIOnly, setShowAIOnly] = useState(true);
  const [badgeImageUrl, setBadgeImageUrl] = useState<string>('');
  const { userId } = useParams<{ userId: string }>();
  const { viewedUser, fetchUserData } = useUserStore();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const badgeRef = useRef<HTMLElement | null>(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const isOwnProfile = currentUser?.uid === userId;

  const renderCreateProfileCTA = () => (
    <div className="mt-12 px-4 max-w-[800px] mx-auto">
      <Card className="w-full border p-6 space-y-6">
        <CardContent>
          <div className="space-y-6 text-center">
            <h3 className="text-xl font-bold">
              Create Your Own Ctrl AI Profile
            </h3>
            
            <p className="text-gray-600 dark:text-gray-400">
              Join thousands of professionals mapping their path to AI mastery. 
              Create your free Ctrl AI Profile in 2 minutes.
            </p>

            <div className="grid grid-cols-2 gap-4 my-10">
              <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                <p className="text-2xl font-bold">8k+</p>
                <p className="text-sm text-gray-600">Active professions</p>
              </div>
              <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                <p className="text-2xl font-bold">83%</p>
                <p className="text-sm text-gray-600">Report increased productivity</p>
              </div>
            </div>

            <Button 
              size="lg"
              className="w-full py-6 text-lg hover:bg-blue-500 rounded-full"
              onClick={() => navigate('/onboarding')}
            >
              Create Your Free Profile
            </Button>

            <p className="text-sm text-gray-500">
              No credit card required · Set up in 2 minutes
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  useEffect(() => {
    const loadUserData = async () => {
      if (userId) {
        try {
          const currentPath = window.location.pathname;
          if (currentPath.startsWith('/u/')) {
            await fetchUserData(userId);
          }
        } catch (error) {
          console.error('Error loading user data:', error);
        }
      }
    };

    useUserStore.getState().resetViewedUser();
    loadUserData();

    return () => {
      useUserStore.getState().resetViewedUser();
    };
  }, [userId, fetchUserData]);

  useEffect(() => {
    if (viewedUser && viewedUser.ogData) {
      
      // Check if user has a valid og image generated
      const hasValidOgImage = Boolean(
        viewedUser.ogData?.generated && 
        viewedUser.ogData?.imageUrl
      );
      
      const ogImageUrl = hasValidOgImage ? viewedUser.ogData.imageUrl : '';
      const shareUrl = `${window.location.origin}/u/${viewedUser.id}`;
      const shareTitle = `${viewedUser.name}'s AI Integration Badge - Ctrl AI`;
      const shareDescription = "Check out my AI integration progress and achievements!";

      // Update meta tags if we're in the browser
      if (typeof window !== 'undefined') {
        const updateMetaTag = (property: string, content: string) => {
          let meta = document.querySelector(`meta[property="${property}"]`);
          if (!meta) {
            meta = document.createElement('meta');
            meta.setAttribute('property', property);
            document.head.appendChild(meta);
          }
          meta.setAttribute('content', content);
        };

        // Update all meta tags
        updateMetaTag('og:title', shareTitle);
        updateMetaTag('og:description', shareDescription);
        updateMetaTag('og:url', shareUrl);
        if (ogImageUrl) {
          updateMetaTag('og:image', ogImageUrl);
        }

        updateMetaTag('twitter:title', shareTitle);
        updateMetaTag('twitter:description', shareDescription);
        updateMetaTag('twitter:url', shareUrl);
        if (ogImageUrl) {
          updateMetaTag('twitter:image', ogImageUrl);
        }

        // Update document title
        document.title = shareTitle;

      }
    }
  }, [viewedUser]);

  useEffect(() => {
    const badgeElement = document.querySelector('.badge-svg-container');
    if (badgeElement) {
      badgeRef.current = badgeElement as HTMLElement;
    }
  }, [viewedUser]);

  const handleOpenShareModal = () => {
    if (!viewedUser) return;

    const badgeElement = document.querySelector('.badge-svg-container');
    if (!badgeElement) {
      toast({
        title: "Error",
        description: "Badge element not found",
        variant: "destructive",
      });
      return;
    }

    badgeRef.current = badgeElement as HTMLElement;
    setIsShareModalOpen(true);
  };

  const getMetaTags = () => {
    if (!viewedUser || !viewedUser.ogData) return null;

    const hasValidOgImage = Boolean(
      viewedUser.ogData?.generated && 
      viewedUser.ogData?.imageUrl
    );
    
    const ogImageUrl = hasValidOgImage ? viewedUser.ogData.imageUrl : '';
    const shareUrl = `${window.location.origin}/u/${viewedUser.id}`;
    const shareTitle = `${viewedUser.name}'s AI Integration Badge - Ctrl AI`;
    const shareDescription = "Check out my AI integration progress and achievements!";

    return (
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{shareTitle}</title>
        <meta name="title" content={shareTitle} />
        <meta name="description" content={shareDescription} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:title" content={shareTitle} />
        <meta property="og:description" content={shareDescription} />
        {ogImageUrl && <meta property="og:image" content={ogImageUrl} />}

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={shareUrl} />
        <meta property="twitter:title" content={shareTitle} />
        <meta property="twitter:description" content={shareDescription} />
        {ogImageUrl && <meta property="twitter:image" content={ogImageUrl} />}
      </Helmet>
    );
  };

  return (
    <>
      {getMetaTags()}
      <div className="w-full min-h-screen p-4 bg-white dark:bg-gray-950 text-black dark:text-white">
        <div className="space-y-8">
          <div className="w-full">
            <Badge 
              layout={layout} 
              userId={userId || ''} 
              showAIOnly={showAIOnly}
              onLayoutChange={setLayout}
              user={viewedUser}
              showUserInfo={true}
              onOpenShareModal={isOwnProfile ? handleOpenShareModal : undefined}
              isDemo={false}
              hideShareMenu={!isOwnProfile}
            />
          </div>
        </div>

        {/* Only show ShareBadgeModal for own profile */}
        {isOwnProfile && (
          <ShareBadgeModal
            isOpen={isShareModalOpen}
            onClose={() => setIsShareModalOpen(false)}
            badgeElement={badgeRef.current}
            userId={userId || ''}
            imageUrl={badgeImageUrl}
          />
        )}

        {/* Show CTA only for non-logged-in users viewing others' profiles */}
        {!currentUser && !isOwnProfile && renderCreateProfileCTA()}
      </div>
    </>
  );
};

export default ProfilePage;
