import { create } from 'zustand';
import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { persist } from 'zustand/middleware';
import { User, Task, OgData } from '../types';
import { calculateUserScoreAndLevel } from '../utils/scoreCalculation';
import { uploadProfilePicture } from '../utils/firebaseStorage';
import { DEFAULT_PROFILE_PICTURE, FIREBASE_STORAGE_URLS } from '../constants';
import { auth } from '../firebase';

interface UserState {
  currentUser: User | null;
  lastFetched: number | null;
  isNewUser: boolean;
  isLoading: boolean;
  viewedUser: User | null;
  error: string | null;
  fetchUserData: (userId: string) => Promise<void>;
  updateUserData: (updatedUser: Partial<User>) => Promise<void>;
  updateUserTask: (taskId: string, updatedTask: Task) => Promise<void>;
  addUserTask: (newTask: Task) => Promise<void>;
  deleteUserTask: (taskId: string) => Promise<void>;
  updateUserScore: () => void;
  resetUserState: () => void;
  changeProfession: (newProfessionId: string, newProfessionName: string) => Promise<{ success: boolean; message: string }>;
  clearLoading: () => void;
  updateProfilePicture: (userId: string, file: File) => Promise<{ mainUrl: string; thumbnailUrl: string }>;
  updateOgData: (userId: string, imageUrl: string) => Promise<void>;
  clearCurrentUser: () => void;
  resetViewedUser: () => void;
  handleVerification: (userId: string) => Promise<boolean>;
  handleNewUserSignup: (userId: string) => Promise<void>;
}

const mergeUserData = (localUser: User | null, firestoreUser: User | null): User => {
  if (!localUser) return firestoreUser!;
  if (!firestoreUser) return localUser;

  // Create a Map of tasks using taskId as key for efficient lookup
  const taskMap = new Map(firestoreUser.tasks.map(task => [task.taskId, task]));
  
  // Add local tasks that don't exist in Firestore
  localUser.tasks.forEach(task => {
    if (!taskMap.has(task.taskId)) {
      taskMap.set(task.taskId, task);
    }
  });

  return {
    ...firestoreUser,
    tasks: Array.from(taskMap.values()),
  };
};

// Add this helper function at the top of your file
const createDefaultUser = (partial: Partial<User> = {}): User => ({
  id: '',
  uid: '',
  name: 'Your Name',
  professionId: '',
  professionName: '',
  points: 0,
  aiLevel: 0,
  tasks: [],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  bio: '',
  location: '',
  website: '',
  isPremium: false,
  ...partial
});

const cleanTask = (task: Task): Task => ({
  taskId: task.taskId,
  taskName: task.taskName,
  frequency: task.frequency,
  totalTime: task.totalTime,
  aiAssistedTime: task.aiAssistedTime,
  aiAssistedPercentage: task.aiAssistedPercentage,
  color: task.color,
  createdAt: task.createdAt || new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  aiToolsUsed: task.aiToolsUsed.map(tool => ({
    aiToolId: tool.aiToolId,
    aiToolName: tool.aiToolName,
    aiToolLink: tool.aiToolLink,
    lastUpdated: tool.lastUpdated,
    modelId: tool.modelId,
    modelName: tool.modelName
  }))
});

// Add this helper function before the store creation
const updateMetaTag = (property: string, content: string) => {
  let meta = document.querySelector(`meta[property="${property}"]`);
  if (!meta) {
    meta = document.createElement('meta');
    meta.setAttribute('property', property);
    document.head.appendChild(meta);
  }
  meta.setAttribute('content', content);
};



export const useUserStore = create<UserState>()(
  persist(
    (set, get) => ({
      currentUser: null,
      lastFetched: null,
      viewedUser: null,
      isNewUser: true,
      isLoading: false,
      error: null,

      fetchUserData: async (userId: string) => {
        if (!userId) {
          set({ error: 'No user ID provided', isLoading: false });
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const userDoc = await getDoc(doc(db, 'users', userId));
          
          if (!userDoc.exists()) {
            set({ error: 'User not found', isLoading: false });
            return;
          }

          const userData = {
            ...userDoc.data(),
            id: userDoc.id,
            profilePicture: userDoc.data().profilePicture || {
              mainUrl: '',
              thumbnailUrl: ''
            }
          } as User;

          // Check if this is a profile view or auth user data fetch
          const isProfileView = window.location.pathname.startsWith('/u/');
          const isAuthUserFetch = auth.currentUser?.uid === userId;

          if (isProfileView) {
            set({ viewedUser: userData });
          } else if (isAuthUserFetch) {
            set({ currentUser: userData });
          }

          set({ isLoading: false, lastFetched: Date.now() });
        } catch (error) {
          console.error('Error fetching user data:', error);
          set({ error: 'Failed to fetch user data', isLoading: false });
        }
      },
      updateUserData: async (updatedUser: Partial<User>) => {
        const { currentUser } = get();
        if (!currentUser?.id) {
          console.error('No current user found');
          return;
        }

        try {
          set({ isLoading: true });
          const userRef = doc(db, 'users', currentUser.id);
          
          // Create the update object
          const updateData = {
            ...updatedUser,
            updatedAt: new Date().toISOString()
          };

          // Update Firestore
          await updateDoc(userRef, updateData);

          // Update local state
          set({ 
            currentUser: { 
              ...currentUser, 
              ...updateData 
            },
            isLoading: false 
          });
        } catch (error) {
          console.error('Error updating user data:', error);
          set({ isLoading: false });
          throw error;
        }
      },
      updateUserTask: async (taskId: string, updatedTask: Task) => {
        set({ isLoading: true, error: null });
        const { currentUser } = get();
        if (currentUser?.id) {
          try {
            const updatedTasks = currentUser.tasks.map(task => 
              task.taskId === taskId ? { ...task, ...updatedTask } : task
            );
            await updateDoc(doc(db, 'users', currentUser.id), { tasks: updatedTasks });
            set({ currentUser: { ...currentUser, tasks: updatedTasks }, isLoading: false });
            get().updateUserScore();
          } catch (error) {
            console.error("Error updating user task:", error);
            set({ error: "Failed to update user task", isLoading: false });
          }
        }
      },
      addUserTask: async (newTask: Task) => {
        set({ isLoading: true, error: null });
        const { currentUser } = get();
        if (currentUser?.id) {
          try {
            const updatedTasks = [...currentUser.tasks, newTask];
            await updateDoc(doc(db, 'users', currentUser.id), { tasks: updatedTasks });
            set({ currentUser: { ...currentUser, tasks: updatedTasks }, isLoading: false });
            get().updateUserScore();
          } catch (error) {
            console.error("Error adding user task:", error);
            set({ error: "Failed to add user task", isLoading: false });
          }
        }
      },
      deleteUserTask: async (taskId: string) => {
        set({ isLoading: true, error: null });
        const { currentUser } = get();
        if (currentUser?.id) {
          try {
            const updatedTasks = currentUser.tasks.filter(task => task.taskId !== taskId);
            await updateDoc(doc(db, 'users', currentUser.id), { tasks: updatedTasks });
            set({ currentUser: { ...currentUser, tasks: updatedTasks }, isLoading: false });
            get().updateUserScore();
          } catch (error) {
            console.error("Error deleting user task:", error);
            set({ error: "Failed to delete user task", isLoading: false });
          }
        }
      },
      updateUserScore: () => {
        const { currentUser } = get();
        if (currentUser) {
          const { score, level } = calculateUserScoreAndLevel(currentUser.tasks);
          const updatedUser = { 
            ...currentUser, 
            points: score,
            aiLevel: level 
          };
          set({ currentUser: updatedUser });
        }
      },
      resetUserState: () => {
        // Clear all user-related state
        set({ 
          currentUser: null, 
          isNewUser: true, 
          isLoading: false, 
          error: null,
          viewedUser: null,
          lastFetched: null
        });
        
        // Clear all storage immediately
        if (typeof window !== 'undefined') {
          // Clear all related storage
          localStorage.removeItem('user-storage');
          sessionStorage.removeItem('user-storage');
          localStorage.removeItem('profession-storage');
          sessionStorage.removeItem('profession-storage');
          
          // Clear any other app-specific storage you might have
          const keysToRemove = [];
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key?.startsWith('app:')) {
              keysToRemove.push(key);
            }
          }
          keysToRemove.forEach(key => localStorage.removeItem(key));
        }
      },
      changeProfession: async (newProfessionId: string, newProfessionName: string) => {
        const { currentUser } = get();
        console.log('Changing profession for user:', currentUser?.id);
        console.log('New profession:', { newProfessionId, newProfessionName });

        if (!currentUser) {
          console.error('No user logged in');
          return { success: false, message: 'No user logged in' };
        }

        try {
          set({ isLoading: true });
          
          const userRef = doc(db, 'users', currentUser.id);
          const updateData = {
            professionId: newProfessionId,
            professionName: newProfessionName,
            lastProfessionChange: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };

          // Update in Firestore
          await updateDoc(userRef, updateData);
          
          // Update local state
          set({ 
            currentUser: {
              ...currentUser,
              ...updateData
            },
            isLoading: false 
          });
          
          console.log('Profession updated successfully');
          return { success: true, message: 'Profession changed successfully' };
        } catch (error) {
          console.error('Error changing profession:', error);
          set({ isLoading: false });
          return { success: false, message: 'Failed to change profession' };
        }
      },
      clearLoading: () => set({ isLoading: false }),  // Add this line
      updateProfilePicture: async (userId: string, file: File) => {
        try {
          const urls = await uploadProfilePicture(userId, file);
          
          // Update local state
          const currentUser = get().currentUser;
          if (currentUser) {
            set({
              currentUser: {
                ...currentUser,
                profilePicture: {
                  mainUrl: urls.mainUrl,
                  thumbnailUrl: urls.thumbnailUrl
                }
              }
            });
          }

          return urls;
        } catch (error) {
          console.error('Error updating profile picture:', error);
          throw error;
        }
      },
      updateOgData: async (userId: string, imageUrl: string) => {
        try {
          const userRef = doc(db, 'users', userId);
          
          await updateDoc(userRef, {
            'ogData': {
              imageUrl,
              generated: true,
              lastGenerated: new Date().toISOString()
            }
          });

          // Update meta tags
          const userData = get().currentUser;
          if (userData) {
            const shareUrl = `${window.location.origin}/u/${userId}`;
            const shareTitle = `${userData.name}'s AI Integration Badge - Ctrl AI`;
            const shareDescription = "Check out my AI integration progress and achievements!";

            // Update meta tags if we're in the browser
            if (typeof window !== 'undefined') {
              updateMetaTag('og:url', shareUrl);
              updateMetaTag('og:title', shareTitle);
              updateMetaTag('og:description', shareDescription);
              updateMetaTag('og:image', imageUrl);
              
              updateMetaTag('twitter:url', shareUrl);
              updateMetaTag('twitter:title', shareTitle);
              updateMetaTag('twitter:description', shareDescription);
              updateMetaTag('twitter:image', imageUrl);
            }
          }
        } catch (error) {
          console.error('Error updating OG data:', error);
          throw error;
        }
      },
      clearCurrentUser: () => {
        set({ currentUser: null });
      },
      resetViewedUser: () => {
        set({ viewedUser: null });
      },
      handleVerification: async (userId: string) => {
        set({ isLoading: true });

        try {
          // Fetch the latest user data
          await get().fetchUserData(userId);
          
          set({ isLoading: false });
          return true;
        } catch (error) {
          console.error('Error handling verification:', error);
          set({ isLoading: false });
          return false;
        }
      },
      handleNewUserSignup: async (userId: string) => {
        console.log('UserStore - Handling new user signup');
        set({ isLoading: true });
        
        try {
          const docRef = doc(db, 'users', userId);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            const userData = docSnap.data();
            
            // If it's a new user and we're in onboarding with temp data
            if (userData.isNewUser) {
            } else {
              console.log('User exists, fetching data without conversion');
              // Ensure tasks array exists when fetching
              const existingData = docSnap.data();
              if (!existingData.tasks) {
                // If tasks don't exist, initialize them
                await updateDoc(docRef, {
                  tasks: [],
                  points: 0,
                  aiLevel: 0
                });
              }
              await get().fetchUserData(userId);
            }
          } else {
            // Create new user with initialized arrays if document doesn't exist
            const now = new Date().toISOString();
            const newUserDoc = {
              id: userId,
              uid: userId,
              name: auth.currentUser?.displayName || 'User',
              professionId: '',
              professionName: '',
              points: 0,
              aiLevel: 0,
              tasks: [], // Initialize empty array
              createdAt: now,
              updatedAt: now,
              bio: '',
              location: '',
              website: '',
              isPremium: false,
              profilePicture: {
                mainUrl: '',
                thumbnailUrl: ''
              },
              lastProfessionChange: now,
              ogData: {
                imageUrl: null,
                generated: false,
                lastGenerated: null
              }
            };
            await setDoc(docRef, newUserDoc);
            set({ currentUser: newUserDoc });
          }
          
          
        } catch (error) {
          console.error('Error handling new user signup:', error);
          set({ error: 'Failed to handle user signup', isLoading: false });
          throw error;
        }
      },
    }),
    {
      name: 'user-storage',
      partialize: (state) => ({
        currentUser: state.currentUser,
        lastFetched: state.lastFetched,
      }),
    }
  )
);
