import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { toast } from '../components/ui/use-toast';
import { Spinner } from '../components/ui/Spinner';

export const VerifyEmailPage: React.FC = () => {
  const { currentUser, isEmailVerified, sendEmailVerification } = useAuth();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkVerification = async () => {
      if (!currentUser) {
        navigate('/');
        return;
      }

      // Check if this is a redirect from email verification
      const isEmailVerificationRedirect = location.search.includes('mode=verifyEmail');

      try {
        // Force refresh the user to get the latest emailVerified status
        await currentUser.reload();
        
        // If user is verified, redirect to dashboard
        if (currentUser.emailVerified) {
          toast({
            title: "Email verified",
            description: "Your email has been verified successfully!",
          });
          navigate('/dashboard', { replace: true });
          return;
        }
        
        // If this was a verification redirect but user is still not verified
        if (isEmailVerificationRedirect && !currentUser.emailVerified) {
          toast({
            title: "Verification failed",
            description: "Please try verifying your email again or contact support.",
            variant: "destructive",
          });
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
        setError(error instanceof Error ? error.message : 'Failed to check verification status');
      } finally {
        setIsChecking(false);
      }
    };

    // Add a small delay to ensure Firebase auth state is updated
    const timer = setTimeout(checkVerification, 1000);
    return () => clearTimeout(timer);
  }, [currentUser, navigate, location]);

  // Add automatic periodic checks
  useEffect(() => {
    if (!currentUser || currentUser.emailVerified) return;

    const checkInterval = setInterval(async () => {
      try {
        await currentUser.reload();
        if (currentUser.emailVerified) {
          toast({
            title: "Email verified",
            description: "Your email has been verified successfully!",
          });
          navigate('/dashboard', { replace: true });
        }
      } catch (error) {
        console.error('Error in periodic check:', error);
        setError(error instanceof Error ? error.message : 'Failed to check verification status');
      }
    }, 60000);

    return () => clearInterval(checkInterval);
  }, [currentUser, navigate]);

  // Add a refresh button
  const handleRefreshStatus = async () => {
    if (!currentUser) return;
    
    try {
      setError(null);
      await currentUser.reload();
      if (currentUser.emailVerified) {
        // First show the success toast
        toast({
          title: "Email verified",
          description: "Your email has been verified successfully!",
        });
        
        // Then reload the page and redirect
        window.location.href = '/dashboard';
      } else {
        toast({
          title: "Not verified",
          description: "Your email is not verified yet. Please check your inbox.",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error('Error refreshing status:', error);
      setError(error instanceof Error ? error.message : 'Failed to refresh status');
    }
  };

  const handleResendVerification = async () => {
    try {
      setError(null);
      await sendEmailVerification();
      setResendDisabled(true);
      setTimeout(() => setResendDisabled(false), 60000); // Enable after 1 minute
      
      toast({
        title: "Verification email sent",
        description: "Please check your inbox and spam folder",
      });
    } catch (error) {
      console.error('Error sending verification email:', error);
      setError(error instanceof Error ? error.message : 'Failed to send verification email');
      
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : 'Failed to send verification email',
        variant: "destructive",
      });
    }
  };

  if (isChecking) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4">Verify Your Email</h1>
        <p className="text-gray-600 dark:text-gray-300 mb-6">
          Please check your email for a verification link. If you've already verified,
          click the refresh button below.
        </p>
        {error && (
          <div className="mb-4 p-3 bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-200 rounded">
            {error}
          </div>
        )}
        <div className="space-y-4">
          <Button
            onClick={handleRefreshStatus}
            className="w-full"
          >
            I've verified my email (Refresh Status)
          </Button>
          <Button
            onClick={handleResendVerification}
            disabled={resendDisabled}
            variant="outline"
            className="w-full"
          >
            {resendDisabled ? 'Wait 1 minute before resending' : 'Resend Verification Email'}
          </Button>
        </div>
        <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
          Don't forget to check your spam folder. The email should arrive within a few minutes.
        </p>
      </div>
    </div>
  );
};
