// src/components/EditableBadge.tsx

import React, { useState, useEffect } from 'react';
import Badge from './Badge';
import { EditForm } from './EditForm';
import { ProfessionSelect } from './ProfessionSelect';
import { useUserStore } from '../stores/userStore';
import { Button } from './ui/button';
import { Spinner } from './ui/Spinner';
import { ProfessionBasic, UserAITool } from '../types';
import { debounce } from '../utils/debounce';
import './Badge.css';
import { AuthButton } from './AuthButton';
import { User, Task } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { DEFAULT_PROFILE_PICTURE } from '../constants';
import { ProfessionChangeModal } from './ProfessionChangeModal';
import { calculateUserScoreAndLevel } from '../utils/scoreCalculation';
import { Card, CardContent } from './ui/card';
import { toast } from './ui/use-toast';
import { InsightForm } from './InsightForm';
import { TaskInsightsView } from './TaskInsightsView';
import { CheckIcon, User2, Zap, Rocket, Star, Sparkles, TrendingUp, Brain, BarChart, CheckCircle, Clock, X, ChevronRight, Users, Database, BarChart2, Lightbulb as InsightIcon, ListTodo as TaskIcon } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
import { ShareMenu } from './ShareMenu';
import { Progress } from "./ui/progress";

interface EditableBadgeProps {
  userId: string | null;
  onSave: (task: Task, profession: string) => Promise<void>;
  onAddTask: () => void;
  onEditTask: (task: Task) => void;
  onSignUp: () => void;
  onDelete: (taskId: string) => Promise<void>;
  onOpenShareModal: () => void;
  isOnboarding?: boolean;
}

export const EditableBadge: React.FC<EditableBadgeProps> = ({
  userId,
  onSave,
  onAddTask,
  onOpenShareModal,
  onEditTask,
  onSignUp,
  onDelete,
  isOnboarding = false
}) => {
  const { 
    currentUser, 
    fetchUserData, 
    isLoading: storeLoading,
    error 
  } = useUserStore();
  const { currentUser: authUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState<number>(0);
  const [selectedProfession, setSelectedProfession] = useState<ProfessionBasic | null>(null);
  const [isLargerThan768, setIsLargerThan768] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [isCreatingNewTask, setIsCreatingNewTask] = useState(false);
  const [showProfessionModal, setShowProfessionModal] = useState(false);
  const [showInsightForm, setShowInsightForm] = useState(false);
  const [savedTask, setSavedTask] = useState<Task | null>(null);
  const [savedAITool, setSavedAITool] = useState<UserAITool | null>(null);
  const [showInsightsView, setShowInsightsView] = useState(false);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showTasksList, setShowTasksList] = useState(false);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsLargerThan768(window.innerWidth >= 768);
    }, 100);

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (authUser && authUser.uid) {
      fetchUserData(authUser.uid);
    }
  }, [authUser, fetchUserData]);

  useEffect(() => {
    if (userId) {
      fetchUserData(userId);
    }
  }, [userId, fetchUserData]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.professionId) {
        setSelectedProfession({ 
          id: currentUser.professionId, 
          name: currentUser.professionName 
        });
        setOnboardingStep(currentUser.tasks.length === 0 ? 1 : 2);
      } else {
        setOnboardingStep(0);
      }
    }
  }, [currentUser]);

  const handleProfessionSelect = (profession: ProfessionBasic) => {
    setSelectedProfession(profession);
    setShowProfessionModal(true);
  };

  const handleConfirmProfession = async () => {
    if (!selectedProfession || !authUser) return;
    
    try {
      setIsLoading(true);
      await useUserStore.getState().updateUserData({
        professionId: selectedProfession.id,
        professionName: selectedProfession.name,
        lastProfessionChange: new Date().toISOString()
      });
      
      setShowProfessionModal(false);
      setOnboardingStep(1);
    } catch (error) {
      console.error('Error updating profession:', error);
      toast({
        title: "Error",
        description: "Failed to update profession. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTaskWrapper = () => {
    setIsTransitioning(true);
    
    // Reset all states first
    setShowInsightForm(false);
    setSavedTask(null);
    setSavedAITool(null);
    setEditingTask(null);
    setActiveTask(null);
    setShowInsightsView(false);
    
    // Slightly longer delay for smoother transition
    setTimeout(() => {
      setIsCreatingNewTask(true);
      setIsTransitioning(false);
    }, 150);
  };

  const handleEditTaskWrapper = (task: Task) => {
    if (showInsightsView) {
      setShowInsightsView(false);
    }
    setEditingTask(task);
    setIsCreatingNewTask(false);
    setOnboardingStep(2);
    onEditTask(task);
  };

  const handleSaveTaskWrapper = async (task: Task) => {
    if (!currentUser) return;

    try {
      setIsLoading(true);
      
      // Clean up task data before saving
      const cleanTask: Task = {
        taskId: task.taskId,
        taskName: task.taskName,
        frequency: task.frequency,
        totalTime: task.totalTime,
        aiAssistedTime: task.aiAssistedTime,
        aiAssistedPercentage: task.aiAssistedPercentage,
        color: task.color,
        createdAt: task.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        aiToolsUsed: task.aiToolsUsed.map(tool => ({
          aiToolId: tool.aiToolId,
          aiToolName: tool.aiToolName,
          aiToolLink: tool.aiToolLink,
          lastUpdated: tool.lastUpdated
        }))
      };

      await onSave(cleanTask, currentUser.professionName);
    } catch (error) {
      console.error('Error in handleSaveTaskWrapper:', error);
    } finally {
      setIsLoading(false);
      setIsCreatingNewTask(false);
      setEditingTask(null);
    }
  };

  const handleCancelEdit = () => {
    setEditingTask(null);
    setIsCreatingNewTask(false);
    setOnboardingStep(2);
  };

  const handleDeleteTask = async (taskId: string) => {
    if (!onDelete) return;

    try {
      await onDelete(taskId);
      setEditingTask(null);
      setIsCreatingNewTask(false);
      setOnboardingStep(2);
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleViewTaskInsights = (task: Task) => {
    // Only allow viewing insights for logged-in users
    if (!currentUser) {
      toast({
        title: "Sign up required",
        description: "Please sign up to view and share insights with the community.",
      });
      return;
    }

    // Reset all states first
    setShowInsightForm(false);
    setSavedTask(null);
    setSavedAITool(null);
    setIsLoading(false);

    // Then set new states
    setActiveTask(task);
    setShowInsightsView(true);
  };

  // Update the Badge click handler to ensure proper state reset
  const handleBadgeClick = (task: Task) => {
    setIsTransitioning(true);
    
    // Reset all states first
    setShowInsightForm(false);
    setSavedTask(null);
    setSavedAITool(null);
    setEditingTask(null);
    setIsCreatingNewTask(false);
    
    // Slightly longer delay for smoother transition
    setTimeout(() => {
      setShowInsightsView(true);
      setActiveTask(task);
      setIsTransitioning(false);
    }, 150);
  };

  const TaskProgressFeedback = ({ taskCount }: { taskCount: number }) => {
    const getFeedbackContent = () => {
      switch (taskCount) {
        case 0:
          return {
            title: "Begin Your AI Integration Journey",
            description: "Start with one task where AI enhances your work. Your Ctrl AI Profile will visualize your growing expertise.",
            button: "Add Your First AI-Enhanced Task",
            items: [
              {
                icon: <BarChart className="h-5 w-5 text-blue-500" />,
                text: "Track your AI-enhanced productivity"
              },
              {
                icon: <Users className="h-5 w-5 text-blue-500" />,
                text: "Compare with peers in your profession"
              },
              {
                icon: <Brain className="h-5 w-5 text-blue-500" />,
                text: "Get personalized optimization insights"
              }
            ]
          };
        
        case 1:
          return {
            title: "Great Start! Add 2 More Tasks",
            description: "Unlock more insights and features:",
            button: "Add Another Task",
            items: [
              {
                icon: <BarChart2 className="h-5 w-5 text-blue-500" />,
                text: "Personalized AI enhancement scores"
              },
              {
                icon: <Database className="h-5 w-5 text-blue-500" />,
                text: "Compare your AI integration with peers"
              },
              {
                icon: <InsightIcon className="h-5 w-5 text-blue-500" />,
                text: "Task-level community insights"
              }
            ]
          };
        
        case 2:
          return {
            title: "Almost There! Add 1 More Task",
            description: "Complete your profile to unlock:",
            button: "Add Final Core Task",
            items: [
              {
                icon: <Sparkles className="h-5 w-5 text-blue-500" />,
                text: "Your complete AI optimization potential"
              },
              {
                icon: <TrendingUp className="h-5 w-5 text-blue-500" />,
                text: "Professional benchmarking insights"
              },
              {
                icon: <Brain className="h-5 w-5 text-blue-500" />,
                text: "AI integration effectiveness score"
              }
            ]
          };
        
        default:
          return {
            title: "Amplify Your Impact",
            description: "Share your Ctrl AI Profile to:",
            button: "Add Another Task",
            items: [
              {
                icon: <Star className="h-5 w-5 text-blue-500" />,
                text: "Inspire peers with your AI innovation"
              },
              {
                icon: <BarChart2 className="h-5 w-5 text-blue-500" />,
                text: "Strengthen your professional network"
              },
              {
                icon: <Database className="h-5 w-5 text-blue-500" />,
                text: "Help build the world's largest AI knowledge base"
              }
            ]
          };
      }
    };

    const content = getFeedbackContent();

    return (
      <div className="space-y-6 md:px-0">
        {/* Action Button */}
        <Button 
          className="w-full py-6 text-lg" 
          onClick={handleAddTaskWrapper}
        >
          {content.button}
        </Button>
        
        {/* Content Card - now full width */}
        <div className="px-4 md:px-0">
          <Card className="p-4 md:px-6 md:py-12">
            <div className="space-y-6">
              <div className="space-y-2">
                <h3 className="text-xl font-semibold">{content.title}</h3>
                {content.description && (
                  <p className="text-sm text-muted-foreground">
                    {content.description}
                  </p>
                )}
              </div>
              
              {content.items.length > 0 && (
                <div className="space-y-4">
                  {content.items.map((item, index) => (
                    <div key={index} className="flex items-center gap-3">
                      {item.icon}
                      <p className="text-muted-foreground">{item.text}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
};

  const renderContent = () => {
    if (error) return <p className="text-red-500">{error}</p>;

    const user = currentUser;
    if (!user) return null;
    
    const taskCount = user?.tasks?.length || 0;
    const isRegisteredUser = !!currentUser;

    const { totalTasks, monthlyHoursSaved, uniqueTools } = calculateUserStats(user.tasks);


    // Show dashboard only for registered users with 3+ tasks
    if (isRegisteredUser && taskCount >= 3) {
      return (
        <div className="space-y-6 md:space-y-8 md:rounded-lg">
          {/* Header and Stats - add padding on desktop to match card sections */}
          <div className="md:card md:bg-white md:dark:bg-gray-950 ">
            {/* Header */}
            <div className="space-y-2 px-4 md:px-6 md:pb-6">
              <h2 className="text-xl md:text-2xl font-bold">
                Welcome to Your Ctrl AI Command Center
              </h2>
              <p className="text-sm md:text-base text-muted-foreground">
                Track, optimize, and share your AI-enhanced workflow
              </p>
            </div>

            {/* Stats Grid */}
            <div className="grid grid-cols-3 gap-2 md:gap-4 px-4 md:px-6 md:pb-6">
              <DashboardCard
                title="Tasks Optimized"
                value={totalTasks}
                icon={CheckCircle}
                iconColor="text-green-500"
                onClick={() => setShowTasksList(!showTasksList)}
              />
              <DashboardCard
                title="Hours Saved Monthly"
                value={monthlyHoursSaved}
                icon={Clock}
                iconColor="text-violet-500"
                onClick={() => setShowTasksList(!showTasksList)}
              />
              <DashboardCard
                title="AI Tools Mastered"
                value={uniqueTools}
                icon={Zap}
                iconColor="text-blue-500"
                clickable={false}
              />
            </div>
          </div>

          {/* Wrap the rest of the content in a Card only on desktop */}
          <div className="md:px-0">
            <div className="md:card md:p-6">
              {/* Tasks List */}
              {showTasksList && (
                <div className="px-4 md:px-0 mb-6">
                  <Card className="p-4 md:p-6">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-semibold">Your Optimized Tasks</h3>
                      <Button variant="ghost rounded-full" onClick={() => setShowTasksList(false)}>
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                    <div className="space-y-2">
                      {user.tasks.map(task => (
                        <div
                          key={task.taskId}
                          className="flex items-center justify-between p-3 hover:bg-muted rounded-lg cursor-pointer relative"
                          onClick={() => handleBadgeClick(task)}
                        >
                          {/* Left border instead of dot */}
                          <div 
                            className="absolute left-0 top-0 bottom-0 w-[3px]"
                            style={{ backgroundColor: task.color }}
                          />
                          <div className="flex items-center gap-3 pl-4">
                            <span>{task.taskName}</span>
                          </div>
                          <ChevronRight className="h-4 w-4 text-muted-foreground" />
                        </div>
                      ))}
                    </div>
                  </Card>
                </div>
              )}

              {/* Amplify Impact Section */}
              <div className="px-4 md:px-0">
                <Card className="p-4 md:px-6 md:py-12">
                  <div className="space-y-6">
                    <div className="space-y-2">
                      <h3 className="text-xl font-semibold">Amplify Your Impact</h3>
                      <p className="text-sm text-muted-foreground">
                        The more professionals join, the smarter we all become
                      </p>
                    </div>

                    {/* Impact items */}
                    <div className="space-y-4">
                      {[
                        {
                          icon: Sparkles,
                          text: "Inspire peers with your AI innovation"
                        },
                        {
                          icon: Users,
                          text: "Strengthen your professional network"
                        },
                        {
                          icon: Database,
                          text: "Help build the world's largest AI knowledge base"
                        }
                      ].map((item, index) => (
                        <div key={index} className="flex items-center gap-3">
                          <item.icon className="h-5 w-5 text-blue-500" />
                          <p>{item.text}</p>
                        </div>
                      ))}
                    </div>

                    {/* Share and Add Task buttons - full width on mobile */}
                    <div className="flex flex-col md:flex-row gap-3 pt-4">
                      <ShareMenu 
                        onOpenShareModal={onOpenShareModal} 
                        className="w-full md:flex-[2] bg-primary text-primary-foreground hover:bg-blue-500 py-6 text-lg"
                      />
                      <Button
                        variant="outline"
                        className="w-full md:flex-1 py-6 text-lg"
                        onClick={handleAddTaskWrapper}
                      >
                        Add Task
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>

              {/* Benchmark Preview Section */}
              <div className="px-4 md:px-0 mt-14">
                <Card className="p-4 md:px-6 md:py-12 border-dashed">
                  <div className="space-y-10">
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <BarChart2 className="h-6 w-6 text-blue-500" />
                        <h3 className="text-xl font-semibold">Profession Benchmarks Coming Soon</h3>
                      </div>
                      <p className="text-muted-foreground">
                        We're gathering data from professionals like you to create powerful insights
                      </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="space-y-4">
                        <h4 className="font-medium">What's Coming:</h4>
                        <ul className="space-y-3">
                          {[
                            "Task-specific AI benchmarks",
                            "Popular AI tools in your field",
                            "Implementation best practices"
                          ].map((item, index) => (
                            <li key={index} className="flex items-center gap-2 text-muted-foreground">
                              <CheckIcon className="h-4 w-4 text-green-500" />
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="space-y-6">
                        <div className="space-y-4">
                          <h4 className="font-medium">Help Us Get There Faster:</h4>
                          {[
                            {
                              icon: Users,
                              text: "Share with peers in your field"
                            },
                            {
                              icon: InsightIcon,
                              text: "Add insights to your tasks"
                            },
                            {
                              icon: TaskIcon,
                              text: "Add more AI-enhanced tasks"
                            }
                          ].map((item, index) => (
                            <div key={index} className="flex items-center gap-2 text-muted-foreground">
                              <item.icon className="h-4 w-4 text-blue-500" />
                              <span>{item.text}</span>
                            </div>
                          ))}
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // For all other cases, show onboarding flow
    switch (onboardingStep) {
      case 0:
        return (
          <div className="md:card md:bg-white md:dark:bg-gray-950 md:px-6 border-none">
            <div className="space-y-6 p-4 md:p-6">
              {/* Header */}
              <div className="space-y-2">
                <h2 className="text-xl md:text-2xl mb-2 font-bold">
                  Join Your Professional Community
                </h2>
                <p className="text-md md:text-base mb-4 text-muted-foreground">
                  Select from 8,000+ professions to connect with peers who are mastering AI in your field
                </p>
              </div>

              {/* Profession Select wrapped in Card */}
              <Card className="p-4 md:p-0 border-none">
                <ProfessionSelect
                  selectedProfession={selectedProfession}
                  onSelect={handleProfessionSelect}
                  isInitialSelection={true}
                />
              </Card>
            </div>
          </div>
        );

      case 1:
        return (
          <div className="md:card md:bg-white md:dark:bg-gray-950 md:px-6 md:rounded-lg md:dark:border-black">
            <div className="space-y-6 p-4 md:p-6">
              {/* Header */}
              <div className="space-y-2">
                <h2 className="text-xl md:text-2xl font-bold">
                  Begin Your AI Integration Journey
                </h2>
                <p className="text-sm md:text-base text-muted-foreground">
                  Start with one task where AI enhances your work. Your Ctrl AI Profile will visualize your growing expertise.
                </p>
              </div>

              {/* Action Card */}
              <Card className="p-4 md:p-6">
                <div className="space-y-6">
                  <div className="space-y-4">
                    {/* Benefits list */}
                    {[
                      {
                        icon: BarChart,
                        text: "Track your AI-enhanced productivity"
                      },
                      {
                        icon: Users,
                        text: "Compare with peers in your profession"
                      },
                      {
                        icon: Brain,
                        text: "Get personalized optimization insights"
                      }
                    ].map((item, index) => (
                      <div key={index} className="flex items-center gap-3">
                        <item.icon className="h-5 w-5 text-blue-500" />
                        <p className="text-muted-foreground">{item.text}</p>
                      </div>
                    ))}
                  </div>

                  <Button 
                    className="w-full py-6 text-lg" 
                    onClick={handleAddTaskWrapper}
                  >
                    Begin Optimizing Your Workflow
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="flex flex-col items-left justify-center md:px-6 space-y-16">
            <h2 className="text-2xl font-bold text-left">
              {taskCount === 1
                ? "Excellent Start! Your AI Journey Begins"
                : taskCount === 2
                ? "You're Making Great Progress!"
                : "Ready to Unlock Full Potential?"
              }
            </h2>

            <div className="flex flex-col items-left space-y-8 w-full">
              {/* Progress feedback for all users */}
              <TaskProgressFeedback taskCount={taskCount} />

              {/* Sign up prompt for non-registered users */}
              {!isRegisteredUser && taskCount >= 1 && (
                <Card className="w-full border p-6 space-y-6">
                  <CardContent>
                    <div className="space-y-6 text-center">
                      <h3 className="text-xl font-bold">
                        {taskCount >= 3 
                          ? "🎉 You're Ready for More!" 
                          : "Save Your Progress & Join the Community"}
                      </h3>
                      
                      <p className="text-gray-600 dark:text-gray-400">
                        {taskCount >= 3
                          ? "Create your account to unlock benchmarks, insights, and connect with peers."
                          : "Sign up to save your progress and unlock more features as you grow."}
                      </p>

                      {taskCount >= 3 && (
                        <div className="grid grid-cols-2 gap-4 my-6">
                          <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                            <p className="text-2xl font-bold">53%</p>
                            <p className="text-sm text-gray-600">Higher productivity</p>
                          </div>
                          <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                            <p className="text-2xl font-bold">14.2h</p>
                            <p className="text-sm text-gray-600">Weekly time saved</p>
                          </div>
                        </div>
                      )}

                      <AuthButton
                        onSignUp={onSignUp}
                        initialMode="signup"
                      />
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  // Add this effect to update onboardingStep when tasks change
  useEffect(() => {
    if (currentUser) {
      const taskCount = currentUser?.tasks?.length || 0;
      
      if (currentUser.professionId) {
        setOnboardingStep(taskCount === 0 ? 1 : 2);
      } else {
        setOnboardingStep(0);
      }
    }
  }, [currentUser]);

  // Add effect to log state changes
  useEffect(() => {

  }, [isLoading, storeLoading, editingTask, isCreatingNewTask, onboardingStep, currentUser]);

  // Add logging to the main render


  return (
    <div className="min-h-screen flex flex-col md:flex-row pt-[72px] md:pt-6">
      {/* Left half - Badge */}
      <div className="w-full md:w-1/2 md:fixed md:left-0 md:top-0 md:bottom-0 flex items-center justify-center px-2 md:px-4">
        <div className="w-full max-w-[700px]">
          <Badge
            userId={userId}
            showAIOnly={true}
            isEditable={true}
            onAddTask={handleAddTaskWrapper}
            onEditTask={handleBadgeClick}
            user={currentUser}
            layout="centered"
            onOpenShareModal={onOpenShareModal}
          />
        </div>
      </div>

      {/* Right half - Scrollable Content */}
      <div className="w-full md:w-1/2 md:ml-[50%] pb-12 px-0 md:px-4 md:pt-[60px] pt-4">
        <div className="max-w-[700px] mx-auto">
          <div style={{ ...fadeStyles, ...(isTransitioning ? fadeOutStyles : {}) }}>
            {showInsightForm && savedTask && savedAITool ? (
              <InsightForm
                task={savedTask}
                aiTool={savedAITool}
                userId={currentUser?.id || ''}
                onComplete={() => {
                  setShowInsightForm(false);
                  setSavedTask(null);
                  setSavedAITool(null);
                  setEditingTask(null);
                  setIsCreatingNewTask(false);
                  setIsLoading(false);
                  setOnboardingStep(2);
                }}
              />
            ) : showInsightsView && activeTask ? (
              <TaskInsightsView
                task={activeTask}
                onEdit={() => {
                  setShowInsightsView(false);
                  handleEditTaskWrapper(activeTask);
                }}
                onBack={() => {
                  setShowInsightsView(false);
                  setActiveTask(null);
                }}
                onClose={() => {
                  setShowInsightsView(false);
                  setActiveTask(null);
                }}
              />
            ) : editingTask || isCreatingNewTask ? (
              <EditForm
                user={currentUser}
                task={editingTask}
                onSave={handleSaveTaskWrapper}
                onCancel={handleCancelEdit}
                onDelete={onDelete}
              />
            ) : (
              <div className="md:card md:bg-white md:dark:bg-gray-950 md:border md:space-y-4 md:rounded-lg md:dark:border-black md:py-1">
                <div className="md:py-6">
                  {renderContent()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <ProfessionChangeModal
        isOpen={showProfessionModal}
        onClose={() => setShowProfessionModal(false)}
        onConfirm={handleConfirmProfession}
        currentProfession={currentUser?.professionName || ''}
        newProfession={selectedProfession?.name || ''}
        isPremium={currentUser?.isPremium}
      />
    </div>
  );
};

// Add this CSS class to your Badge.css or create a new style block
const fadeStyles = {
  transition: 'opacity 150ms ease-in-out',
  opacity: 1,
};

const fadeOutStyles = {
  opacity: 0,
  pointerEvents: 'none' as const,
};

// Update the helper function
const calculateUserStats = (tasks: Task[]) => {
  const totalTasks = tasks.length;
  const monthlyMinutesSaved = tasks.reduce((total, task) => {
    return total + (task.aiAssistedTime * task.frequency);
  }, 0);
  
  // Convert to hours and minutes
  const hours = Math.floor(monthlyMinutesSaved / 60);
  const minutes = Math.floor(monthlyMinutesSaved % 60);
  
  // Format the time string with padded minutes
  const monthlyHoursSaved = `${hours}:${minutes.toString().padStart(2, '0')}h`;
  
  const uniqueTools = new Set(tasks.flatMap(task => 
    task.aiToolsUsed.map(tool => tool.aiToolName)
  )).size;

  return {
    totalTasks,
    monthlyHoursSaved,
    uniqueTools
  };
};

// Update the DashboardCard component to be more compact on mobile
const DashboardCard = ({ 
  title, 
  value, 
  icon: Icon,
  onClick,
  iconColor,
  clickable = true
}: { 
  title: string;
  value: number | string;
  icon: LucideIcon;
  onClick?: () => void;
  iconColor: string;
  clickable?: boolean;
}) => (
  <Card 
    className={`relative overflow-hidden transition-all hover:shadow-md ${clickable ? 'cursor-pointer' : ''}`}
    onClick={clickable ? onClick : undefined}
  >
    <CardContent className="p-4 md:p-6 space-y-1 md:space-y-2">
      <Icon className={`h-5 w-5 md:h-6 md:w-6 ${iconColor}`} />
      <p className="text-2xl md:text-3xl font-bold">{value}</p>
      <p className="text-xs md:text-sm text-muted-foreground">{title}</p>
    </CardContent>
  </Card>
);
