import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  getAuth,
  signInWithPopup, 
  GoogleAuthProvider, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut,
  updateProfile,
  onAuthStateChanged,
  User,
  UserCredential,
  sendEmailVerification as firebaseSendEmailVerification
} from 'firebase/auth';
import { useUserStore } from '../stores/userStore';
import { FirebaseError } from 'firebase/app';
import { auth } from '../firebase';
import { DEFAULT_PROFILE_PICTURE } from '../constants';
import { useThemeStore } from '../stores/themeStore';
import { doc, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

interface AuthContextType {
  currentUser: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string, name: string) => Promise<UserCredential>;
  signInWithGoogle: () => Promise<UserCredential>;
  signOut: () => Promise<void>;
  isLoading: boolean;
  error: string | null;
  sendEmailVerification: () => Promise<void>;
  isEmailVerified: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isDarkMode } = useThemeStore();
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      // Listen for auth-related storage changes
      if (event.key === 'firebase:authUser:YOUR_API_KEY:web') {
        // Force clear local state when auth state changes in another tab
        setCurrentUser(null);
        setIsEmailVerified(false);
        useUserStore.getState().resetUserState();
      }
    };

    // Add storage event listener for cross-tab communication
    window.addEventListener('storage', handleStorageChange);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Force refresh user data
          await user.reload();
          setCurrentUser(user);
          setIsEmailVerified(user.emailVerified);
        } catch (error) {
          console.error('Error refreshing user:', error);
          // Clear state on error
          setCurrentUser(null);
          setIsEmailVerified(false);
          useUserStore.getState().resetUserState();
        }
      } else {
        // Clear everything when user is null (logged out)
        setCurrentUser(null);
        setIsEmailVerified(false);
        useUserStore.getState().resetUserState();
        
        // Clear all auth-related storage
        localStorage.removeItem('user-storage');
        sessionStorage.removeItem('user-storage');
        localStorage.removeItem('profession-storage');
        sessionStorage.removeItem('profession-storage');
      }
      setIsLoading(false);
    });

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      unsubscribe();
      // Clear state when component unmounts
      setCurrentUser(null);
      setIsEmailVerified(false);
    };
  }, []);

  const handleAuthError = (error: unknown): never => {
    if (error instanceof FirebaseError) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          throw new Error('This email is already in use. Please try signing in instead.');
        case 'auth/weak-password':
          throw new Error('Password should be at least 6 characters long.');
        case 'auth/invalid-email':
          throw new Error('Please enter a valid email address.');
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          throw new Error('Invalid email or password.');
        case 'auth/too-many-requests':
          throw new Error('Too many failed attempts. Please try again later.');
        case 'auth/requires-recent-login':
          throw new Error('Please sign in again to complete this action.');
        case 'auth/operation-not-allowed':
          throw new Error('Email/password accounts are not enabled. Please contact support.');
        default:
          throw new Error(`Authentication error: ${error.message}`);
      }
    }
    throw new Error('An unexpected error occurred.');
  };

  const signIn = async (email: string, password: string) => {
    try {
      setError(null);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Force refresh to get latest verification status
      await userCredential.user.reload();
      if (!userCredential.user.emailVerified) {
        throw new Error('Please verify your email before signing in');
      }
      return userCredential;
    } catch (error) {
      throw handleAuthError(error);
    }
  };

  const signUp = async (email: string, password: string, name: string) => {
    try {
      setError(null);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update the user's profile with the provided name
      await updateProfile(user, {
        displayName: name.trim(),
        photoURL: isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light
      });

      // Create the user document with the provided name
      const userDoc = {
        id: user.uid,
        uid: user.uid,
        name: name.trim(), // Use the provided name
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        isPremium: false,
        tasks: [],
        points: 0,
        aiLevel: 0,
        professionId: '',
        professionName: '',
        bio: '',
        location: '',
        website: '',
        profilePicture: {
          mainUrl: isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light,
          thumbnailUrl: isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light
        },
        lastProfessionChange: new Date().toISOString(),
        ogData: {
          imageUrl: null,
          generated: false,
          lastGenerated: null
        }
      };

      // Set the user document in Firestore
      await setDoc(doc(db, 'users', user.uid), userDoc);

      await firebaseSendEmailVerification(user, {
        url: `${window.location.origin}/verify-email`
      });
      
      return userCredential;
    } catch (error) {
      throw handleAuthError(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setError(null);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      return result;
    } catch (error) {
      throw handleAuthError(error);
    }
  };

  const signOutUser = async () => {
    try {
      setError(null);
      
      // First clear the user store
      useUserStore.getState().resetUserState();
      
      // Clear all storage before signing out
      localStorage.removeItem('user-storage');
      sessionStorage.removeItem('user-storage');
      localStorage.removeItem('profession-storage');
      sessionStorage.removeItem('profession-storage');
      
      // Then sign out from Firebase
      await firebaseSignOut(auth);
      
      // Clear any remaining state
      setCurrentUser(null);
      setIsEmailVerified(false);
      
      // Broadcast logout event to other tabs
      localStorage.setItem('app:logout', Date.now().toString());
      
    } catch (error) {
      throw handleAuthError(error);
    }
  };

  const createUserDocument = async (user: User) => {
    // ... other code ...
    const userData = {
      // ... other fields ...
      points: 0, // Initialize to 0
      aiLevel: 0, // Initialize to 0
      // ... other fields ...
    };
    // ... rest of the function
  };

  const sendEmailVerification = async () => {
    if (!auth.currentUser) throw new Error('No user logged in');
    try {
      await firebaseSendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}/dashboard`,
        handleCodeInApp: true,
      });
    } catch (error) {
      console.error('Error sending verification email:', error);
      if (error instanceof Error) {
        throw new Error(`Failed to send verification email: ${error.message}`);
      }
      throw new Error('Failed to send verification email');
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      useUserStore.getState().resetUserState(); // Clear all user data
      // If you're using react-router, you might want to redirect here
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const value: AuthContextType = {
    currentUser,
    signIn,
    signUp,
    signInWithGoogle,
    signOut: signOutUser,
    isLoading,
    error,
    sendEmailVerification,
    isEmailVerified
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or your loading component
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
